<template>
    <div class="d-flex flex-column-fluid">
        <!--begin::Container-->
        <div class="container-fluid py-8">
            <div class="d-flex flex-row">
                <!--begin::Content-->
                <div class="flex-row-fluid ">
                    <div class="row">
                        <div class="col-lg-12">
                            <form class="form" novalidate="novalidate" id="kt_save_code_form">
                                <!--begin::Card-->
                                <div class="card card-custom gutter-b example example-compact">
                                    <div class="card-header">
                                        <h3 class="card-title">Generate Codes</h3>
                                    </div>
                                    <!--begin::Form-->
                                    <div class="card-body">
                                        <!-- <h3 class="font-size-lg text-dark font-weight-bold mb-6">1. Personal Info:</h3> -->
                                        <div class="mb-15">
                                            <div class="form-group row">
                                                <div class="col-lg-6">
                                                    <label class="font-weight-bolder">Start Date</label>
                                                    <b-form-datepicker name="startdate" ref="startdate"
                                                        v-model="form.startdate"
                                                        class="form-control form-control-solid form-control-lg">
                                                    </b-form-datepicker>
                                                </div>
                                                <div class="col-lg-6">
                                                    <label class="font-weight-bolder">End Date</label>
                                                    <b-form-datepicker name="enddate" ref="enddate"
                                                        v-model="form.enddate"
                                                        class="form-control form-control-solid form-control-lg">
                                                    </b-form-datepicker>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-lg-6">
                                                    <label class="font-weight-bolder">Total</label>
                                                    <input type="number" name="number_licenses" ref="number_licenses"
                                                        class="form-control form-control-solid form-control-lg"
                                                        v-model="form.number_licenses" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-footer">
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <button ref="kt_save_code_changes"
                                                    class="btn btn-primary mr-2">Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <!--end::Form-->
                        </div>
                        <!--end::Card-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Swal from "sweetalert2";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import KTUtil from "@/assets/js/components/util";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

export default {
    name: 'codegenerate',
    props: {
        corpid: String
    },
    data() {
        return {
            form: {
                number_licenses: 0,
                startdate: '',
                enddate: '',
                corpid: this.$route.params.corpId
            },
        }
    },
    mounted() {
        const form = KTUtil.getById("kt_save_code_form");
        this.fv1 = formValidation(form, {
            fields: {
                startdate: {
                    validators: {
                        notEmpty: {
                            message: "Start date is required",
                        },
                    },
                },
                enddate: {
                    validators: {
                        notEmpty: {
                            message: "End date is required",
                        },
                    },
                },
                number_licenses: {
                    validators: {
                        notEmpty: {
                            message: "Status is required",
                        },
                        integer: {
                            message: "The value is not an integer"
                        }
                    },
                }
            },
            plugins: {
                trigger: new Trigger(),
                submitButton: new SubmitButton(),
                bootstrap: new Bootstrap(),
            },
        });

        this.fv1.on("core.form.valid", () => {
            this.isDisabled = true

            // set spinner to submit button
            const submitButton = this.$refs["kt_save_code_changes"];
            submitButton.classList.add("spinner", "spinner-light", "spinner-right");

            let self = this;
            this.$store.dispatch("createNewCodes", this.form)
                .then(
                    function (value) {
                        submitButton.classList.remove(
                            "spinner",
                            "spinner-light",
                            "spinner-right"
                        );
                        Swal.fire({
                            title: "",
                            text: "The codes will created shortly",
                            icon: "success",
                            confirmButtonClass: "btn btn-secondary",
                            heightAuto: true,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                self.$router.push({ name: 'corporatedetail', params: { corpId: self.$route.params.corpId } })
                            }
                        })
                    },
                    function (error) {
                        submitButton.classList.remove(
                            "spinner",
                            "spinner-light",
                            "spinner-right"
                        );
                        Swal.fire({
                            title: "",
                            text: error,
                            icon: "error",
                            confirmButtonClass: "btn btn-secondary",
                            heightAuto: true,
                        });
                    });
        });

        this.fv1.on("core.form.invalid", () => {
            Swal.fire({
                title: "",
                text: "Please, provide correct data!",
                icon: "error",
                confirmButtonClass: "btn btn-secondary",
                heightAuto: true,
            });
        });

    }

}
</script>
